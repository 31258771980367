import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  IPerksResponse,
  IPerkBenefit,
  IMilestonePerk,
  IGetPerksRequest,
} from "../types/perks";
import UserAccountContext from "./UserAccountContext";
import { AccountStateType } from "../state/InitialState";
import { initialStateType, initialState } from "../state/perksState";
import fetchPerksData from "../helpers/fetchPerksData";

type PerksContextType = {
  perksState: initialStateType;
  perksDispatch: Dispatch<ActionType>;
};

export const PerksContext = createContext<PerksContextType>({
  perksState: initialState,
  perksDispatch: () => {},
});

export const ACTIONS = {
  SET_ACTIVE_SCREEN: "SET_ACTIVE_SCREEN",
  SET_SELECTED_MILESTONE_PERK: "SET_SELECTED_MILESTONE_PERK",
  SET_SELECTED_PERK_BENEFITS: "SET_SELECTED_PERK_BENEFITS",
  SET_PERKS: "SET_PERKS",
};

type ActionType = {
  type: string;
  value: string | IPerkBenefit[] | IPerksResponse | IMilestonePerk | null;
};

const perksReducer = (
  state: initialStateType,
  action: ActionType
): initialStateType => {
  switch (action.type) {
    case ACTIONS.SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action.value as string,
      };
    case ACTIONS.SET_SELECTED_MILESTONE_PERK:
      return {
        ...state,
        selectedMilestonePerk: action.value as IMilestonePerk,
      };
    case ACTIONS.SET_SELECTED_PERK_BENEFITS:
      return {
        ...state,
        selectedPerkBenefits: action.value as IPerkBenefit[],
      };
    case ACTIONS.SET_PERKS:
      return {
        ...state,
        perks: action.value as IPerksResponse,
      };
    default:
      return state;
  }
};

export const PerksProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accountState }: { accountState: AccountStateType } =
    useContext(UserAccountContext);
  const [perksState, perksDispatch] = useReducer(perksReducer, initialState);

  useEffect(() => {
    const { MileagePlanNumber, asoaMiles } = accountState.MileagePlanDetails;
    const { Guid, Value } = accountState.Token;

    if (MileagePlanNumber !== "" && Guid !== "" && Value !== "") {
      const payload: IGetPerksRequest = {
        asoaMiles: asoaMiles,
        guid: Guid,
        mileagePlanNumber: MileagePlanNumber,
      };
      const getPerksData = async () => {
        await fetchPerksData(payload, Value, perksDispatch);
      };
      getPerksData();
    }
  }, [accountState]);

  const contextValue = {
    perksDispatch,
    perksState,
  };

  return (
    <PerksContext.Provider value={contextValue}>
      {children}
    </PerksContext.Provider>
  );
};
