export const parseMarkdown = ({markdown}: {markdown: string}) => {
    // Convert headings
    let html = markdown
        .replace(/###### (.+)/g, '<h6>$1</h6>')
        .replace(/##### (.+)/g, '<h5>$1</h5>')
        .replace(/#### (.+)/g, '<h4>$1</h4>')
        .replace(/### (.+)/g, '<h3>$1</h3>')
        .replace(/## (.+)/g, '<h2>$1</h2>')
        .replace(/# (.+)/g, '<h1>$1</h1>');

    // Convert bold and italic
    html = html
        .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>') // Bold
        .replace(/\*(.+?)\*/g, '<em>$1</em>'); // Italics

    // Convert links
    html = html.replace(/\[(.+?)\]\((.+?)\)/g, '<a href="$2">$1</a>');

    // Convert unordered lists
    html = html.replace(/^\s*\*\s*(.+)/gm, '<ul>\n<li>$1</li>\n</ul>');

    // Convert list items properly
    html = html.replace(/<\/ul>\s*<ul>/g, ''); // Remove duplicate <ul>
    html = html.replace(/<\/ul>\s*<li>/g, '<li>'); // Handle list items

    // Convert paragraphs
    html = html.replace(/\n/g, '<br/>');

    return html;
};
