import "./index.scss";
import { useContext } from "react";
import cxs from "cxs";
import MPPerkIcon from "../MPPerkIcon";
import { formatNumber } from "../../../../helpers/formatNumber";
import { getIcon } from "../../../../helpers/getIcon";
import { ContentStateType } from "../../../../state/InitialState";
import { ContentContext } from "../../../../context/ContentContext";
import { PerksContext } from "../../../../context/PerksContext";
import { isNumeric } from "../../../../helpers/isNumeric";
import { isStringContainsDate } from "../../../../helpers/isStringContainsDate";
import { formatDate } from "../../../../helpers/formatDate";
import { replacePlaceholderName } from "../../../../helpers/replacePlaceholderName";

export interface MPPerkCardProps {
  perkBenefitId: number;
  selectionDate?: string;
  indx: number;
}

interface Field {
  title: string;
  value: string;
  order: number | string;
}

export default function MPPerkCard(perk: Readonly<MPPerkCardProps>) {
  const {
    perksState: { perks },
  } = useContext(PerksContext);
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);

  const {
    cardBackgroundColor,
    perkTitleColor,
    fieldValueColor,
    linkColor,
    fieldTitleColor,
  } = contentState?.Content[3]?.perksLanding?.pastPerks || {};

  const {
    list,
    default: defaultIconName,
    defaultCategory,
  } = contentState?.Content[2]?.icons || {};

  const styles = {
    cardContainer: cxs({
      background: `var(--${cardBackgroundColor}, #fff)`,
    }),
    perkTitle: cxs({
      color: `var(--${perkTitleColor}, #2E2E2E)`,
    }),
    fieldValue: cxs({
      color: `var(--${fieldValueColor}, #151515)`,
    }),
    fieldTitle: cxs({
      color: `var(--${fieldTitleColor}, #151515)`,
    }),
    link: cxs({
      color: `var(--${linkColor}, #03314F)`,
    }),
  };

  const getPerk = (perkId: number) => {
    const perk = contentState?.Content[4]?.perks?.find(
      (perk) => perk.id === perkId
    );

    if (perk) {
      return perk;
    }
  };

  const toTitleCase = (str: string) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map((title: string) => title.charAt(0).toUpperCase() + title.slice(1))
      .join(" ");
  };

  const getFormattedValue = (value: string, selected: Field): string => {
    if (isStringContainsDate(selected.value)) {
      return formatDate(value) ?? "";
    }

    if (isNumeric(value)) {
      return formatNumber(value) ?? "";
    }

    return value ?? "";
  };

  const perksContent = getPerk(perk.perkBenefitId);

  return (
    <div
      className={`perk-card ${styles.cardContainer}`}
      data-testid={`past-perk-card-${perk.indx}`}
      key={perk.indx}
    >
      <MPPerkIcon
        id={perk.perkBenefitId}
        type={
          getIcon(
            perk.perkBenefitId,
            list || [],
            defaultCategory,
            defaultIconName
          ).name
        }
        category={
          getIcon(
            perk.perkBenefitId,
            list || [],
            defaultCategory,
            defaultIconName
          ).category
        }
      />
      <div className="perk-card-data">
        <div className={`perk-card-title ${styles.perkTitle}`}>
          {`${toTitleCase(perksContent?.perkSummary as string)}`}
        </div>

        {perksContent?.pastPerksCardProps?.fields?.length ? (
          <div>
            {perksContent.pastPerksCardProps.fields
              .sort((a, b) => Number(a.order) - Number(b.order))
              .map((selected, index) => {
                const value = perks.pastPerksList[perk.indx][
                  selected.value
                ] as string;
                const formattedValue = getFormattedValue(value, selected);

                return (
                  <div className="field-container" key={index}>
                    <div className={`${styles.fieldTitle}`}>
                      {selected.title}
                    </div>
                    <div className={`${styles.fieldValue}`}>
                      {formattedValue}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : null}
        {perksContent?.pastPerksCardProps?.links?.length ? (
          <div className="perk-card-link-container">
            {perksContent.pastPerksCardProps.links
              .sort((a, b) => Number(a.order) - Number(b.order))
              .map((link, index) => {
                // Use a regular expression to match all occurrences of text inside {}
                const matches = link.href.match(/\{(.*?)\}/g);

                // Map over the matches to extract just the strings without the curly braces
                const result = matches
                  ? matches.map((match) => match.slice(1, -1))
                  : [];
                const resultObject: { [key: string]: any } = {};

                result.forEach((key) => {
                  // create object with key value pairs
                  resultObject[key] = perks.pastPerksList[perk.indx][key];
                });

                return (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a
                    className={`perk-card-link ${styles.link}`}
                    key={index}
                    target={
                      perksContent.perkName?.includes("LOUNGE_DAY") &&
                      window.navigator.userAgent
                        .toUpperCase()
                        .includes("ALKAPP/IOS")
                        ? "_self"
                        : "_blank"
                    }
                    href={replacePlaceholderName(link.href, resultObject)}
                  >
                    {link.title}
                  </a>
                );
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
}
