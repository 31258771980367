import { useContext } from "react";
import cxs from "cxs";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { PerksScreens } from "../../../helpers/constants";
import PerksTile from "../MPPerksTile";
import RoundedButton from "../../RoundedButton/RoundedButton";
import { getIcon } from "../../../helpers/getIcon";
import { IEnrollPerksRequest } from "../../../types/perks";
import UserAccountContext from "../../../context/UserAccountContext";
import { enrollPerks } from "../../../services/PerksService";
import "./index.scss";

const MPPerksSelectionConfirmation = () => {
  const { accountState } = useContext(UserAccountContext);
  const { contentState } = useContext(ContentContext);
  const { perksState, perksDispatch } = useContext(PerksContext);

  const { selectedPerkBenefits, selectedMilestonePerk } = perksState;

  const {
    backgroundColor,
    titleColor,
    title,
    subtitle,
    subtitleColor,
    cancelButtonTitle,
    confirmButtonTitle,
    cancelButtonBackgroundColor,
    confirmButtonBackgroundColor,
  } = contentState?.Content[1]?.perkSelection?.confirmSelection || {};

  const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

  const styles = {
    container: cxs({
      background: `var(--${backgroundColor}, #FBFFF6)`,
    }),
    header: cxs({ color: `var(--${titleColor}, #151515)` }),
    subtitle: cxs({ color: `var(--${subtitleColor}, #2e2e2e)` }),
    confirmButton: cxs({
      color: `#fff`,
      backgroundColor: `var(--${confirmButtonBackgroundColor}, #03314f)`,
    }),
    cancelButton: cxs({
      borderColor: `var(--${cancelButtonBackgroundColor}, #ffffff)`,
    }),
  };

  const handleConfirm = async () => {
    try {
      if (selectedMilestonePerk && selectedPerkBenefits.length > 0) {
        const milestoneId = selectedMilestonePerk.memberSelectionLst[0].milestoneId;

        for (const perk of selectedPerkBenefits) {
          const payload: IEnrollPerksRequest = {
            milestoneId: milestoneId,
            enrollPerkId: perk.perkBenefitId,
            firstName: accountState.MileagePlanDetails.FirstName,
            lastName: accountState.MileagePlanDetails.LastName,
            emailAddress: accountState.MileagePlanDetails.Email,
            mpNumber: accountState.MileagePlanDetails.MileagePlanNumber,
            accountGuid: accountState.Token.Guid,
            nominee: {
              firstName: accountState.MVPGoldNominationDetails.FirstName,
              lastName: accountState.MVPGoldNominationDetails.LastName,
              mpNumber: accountState.MVPGoldNominationDetails.MileagePlanNumber,
              emailAddress: accountState.MVPGoldNominationDetails.Email,
            },
          };

          const res = await enrollPerks(payload, accountState.Token.Value);
          console.log("enrollPerks returned: " + res?.status, res, payload);

          perksDispatch({
            type: ACTIONS.SET_ACTIVE_SCREEN,
            value: PerksScreens.PerksSelectionSuccess,
          });
        }
      }
    } catch (error) {
      console.error("Error enrolling perks:", error);
    }
  };

  const handleCancel = () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: PerksScreens.PerksSelection,
    });
  };

  return (
    <div className={`perksConfirmationPage__container ${styles.container}`}>
      <div className="perksConfirmationPage__headerText">
        <h2 className={`perksConfirmationPage__title ${styles.header}`}>{title}</h2>
        <p className={`perksConfirmationPage__subtitle ${styles.subtitle}`}>{subtitle}</p>
      </div>
      <div className="perksConfirmationPage__tilesContainer">
        {selectedPerkBenefits.map((perk) => {
          const icon = getIcon(perk.perkBenefitId, list, defaultCategory, defaultIconName);

          return (
            <PerksTile
              id={perk.perkBenefitId}
              key={perk.perkBenefitId}
              selected={true}
              description={perk.perkDescription}
              redeemUntilDate="redeem by 10/2023"
              icon={icon.name}
              category={icon.category}
              handleClick={() => {}}
              variant="outline"
              isButton={false}
            />
          );
        })}
      </div>
      <div className="perksConfirmationPage__buttons">
        <RoundedButton
          className={styles.confirmButton}
          label={confirmButtonTitle ?? "Confirm"}
          type="button"
          onClick={handleConfirm}
        />
        <RoundedButton
          className={styles.cancelButton}
          label={cancelButtonTitle ?? "Choose a different perk"}
          type="button"
          outline
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};

export default MPPerksSelectionConfirmation;
