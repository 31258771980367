import { parseMarkdown } from '../../helpers/parseMarkdown';

const MarkdownRenderer = ({ markdown }: { markdown: string }) => {
    const htmlContent = parseMarkdown({markdown});

    return (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default MarkdownRenderer;
