export const formatDate = (dateString: string): string | undefined => {
  if (!dateString) return;
  try {
    // Parse the date string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return undefined;
    }

    // Format the date in the desired format
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options);
  } catch {
    // Handle any unexpected errors
    return undefined;
  }
};
