import { useContext } from "react";
import cxs from "cxs";
import { PerksContext } from "../../context/PerksContext";
import { PerksScreens } from "../../helpers/constants";
import { isFromMobileWebView } from "../../services/header-footer";
import { ContentContext } from "../../context/ContentContext";
import "./index.scss";

interface AppWindow extends Window {
  AppInterface?: {
    popBackStack: (shouldPop: boolean) => void;
  };
  webkit?: {
    messageHandlers?: {
      handleBack?: {
        postMessage: (message: any) => void;
      };
    };
  };
}

type PerksNavigationProps = {
  title: string;
  returnToScreen: string;
};

const MPPerksNavigation = (props: PerksNavigationProps) => {
  const { title, returnToScreen } = props;
  const { perksState, perksDispatch } = useContext(PerksContext);

  const { contentState } = useContext(ContentContext);

  const { backIcon, backIconCategory, backIconColor, backgroundColor, titleColor } =
    contentState?.Content[3]?.perksLanding?.topNav || {};

  const styles = {
    navContainer: cxs({
      paddingTop: isFromMobileWebView() ? `4rem` : `2rem`,
      backgroundColor: `var(--${backgroundColor}, #defcd6)`,
    }),
    backButton: cxs({
      color: `var(--${backIconColor}, #2E2E2E)`,
    }),
    title: cxs({
      color: `var(--${titleColor}, var(--${titleColor}, #2E2E2E))`,
    }),
  };

  const appWindow = window as AppWindow;

  const callAppInterface = (shouldPop: boolean) => {
    const appWindow = window as AppWindow;
    if (appWindow.AppInterface && typeof appWindow.AppInterface.popBackStack === "function") {
      appWindow.AppInterface.popBackStack(shouldPop);
    }
  };

  const handleBack = () => {
    if (perksState.activeScreen === PerksScreens.Hub) {
      callAppInterface(true);
      handleBackForIOS();
      window.history.back();
    } else {
      perksDispatch({ type: "SET_ACTIVE_SCREEN", value: returnToScreen });
    }
  };

  const handleBackForIOS = () => {
    if (appWindow.webkit && appWindow.webkit.messageHandlers && appWindow.webkit.messageHandlers.handleBack) {
      appWindow.webkit.messageHandlers.handleBack.postMessage(null);
    }
  };

  (appWindow as any).callHandleBack = handleBack;

  return (
    <div className={`perksNavigation__container ${styles.navContainer}`}>
      <button className={`${styles.backButton}`} onClick={handleBack}>
        <auro-icon
          name={backIcon ?? "chevron-left"}
          category={backIconCategory ?? "interface"}
          customSize
          customColor
        />
      </button>
      <div className="perksNavigation__title">
        <h1 className={styles.title}>{title}</h1>
      </div>
    </div>
  );
};

export default MPPerksNavigation;
