import React, { useContext, useState } from "react";
import "./index.scss";
import PerksBenefitItem, { PerkBenefitItemProps } from "./PerksBenefitItem";
import MilestoneBadge, { MilestoneBadgeType } from "./MilestoneBadge";
import { formatNumber } from "../../../../helpers/formatNumber";
import { ContentStateType } from "../../../../state/InitialState";
import { ContentContext } from "../../../../context/ContentContext";
import { replacePlaceholderName } from "./../../../../helpers/replacePlaceholderName";

export interface ChipDetailProps {
  textColor: string;
  backgroundColor: string;
  subtractColor: string;
  title: string;
}

export interface FuturePerksDetailProps {
  info: string;
  perkBenefitsLst: PerkBenefitItemProps[];
  chip: ChipDetailProps | undefined;
  summary?: string;
  mileStoneStatus?: string;
  oneworldTierstatus?: string;
  qualifyingMiles?: number | string;
  isCurrentMilestone?: boolean;
}

const MPPerksDetailsList: React.FC<FuturePerksDetailProps> = (props) => {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);
  const { eliteQualifyingMiles } =
    contentState?.Content[3]?.perksLanding?.milestoneSummary
      ?.currentMilestoneDetails || {};
  const [isExpanded, setIsExpanded] = useState(false);
  const filterPerkBenefitLst = props.perkBenefitsLst?.filter(
    (item) => item.name !== ""
  );
  const itemsToShow = isExpanded
    ? filterPerkBenefitLst
    : filterPerkBenefitLst?.slice(0, 2);
  const isShowMoreEnable =
    props.perkBenefitsLst?.filter((item) => item.name !== "").length > 2;

  const handleToggleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={!props.qualifyingMiles ? "future-perks-container" : ""}>
      <MilestoneBadge
        textColor={props.chip?.textColor ?? ""}
        backgroundColor={props.chip?.backgroundColor ?? ""}
        offsetColor={props.chip?.subtractColor ?? ""}
        title={props.chip?.title ?? ""}
        type={
          props.chip?.subtractColor === ""
            ? MilestoneBadgeType.Normal
            : MilestoneBadgeType.Colored
        }
      />
      {props.mileStoneStatus && props.oneworldTierstatus && (
        <>
          <div className="current-status-text-container">
            <span className="current-status-tier">
              {props.mileStoneStatus} / one
            </span>
            world{" "}
            <span className={`one-world-${props.oneworldTierstatus}`}>
              {props.oneworldTierstatus}
            </span>
          </div>
        </>
      )}

      {props.qualifyingMiles && (
        <div className="elite-qualifying-miles">
          {replacePlaceholderName(eliteQualifyingMiles || "", {
            qm: formatNumber(props.qualifyingMiles) as string,
          })}
        </div>
      )}

      <p
        className={
          props.isCurrentMilestone
            ? "current-milestone-description"
            : "perk-description"
        }
      >
        {props.info}
      </p>
      <div className="benefit-list">
        {itemsToShow?.map((benefit: PerkBenefitItemProps, index: number) => (
          <PerksBenefitItem key={index} {...benefit} />
        ))}

        <p className="perk-summary">{props.summary}</p>

        {isShowMoreEnable && (
          <div className="show-more" onClick={handleToggleShowMore}>
            {isExpanded && (
              <span>
                <auro-icon
                  test-id="show-less"
                  category="interface"
                  name="chevron-up"
                />
                Show less
              </span>
            )}
            {!isExpanded && (
              <span>
                <auro-icon
                  test-id="show-more"
                  category="interface"
                  name="chevron-down"
                />
                Show more
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MPPerksDetailsList;
