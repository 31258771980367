import { PerksScreens } from "../helpers/constants";
import { IMilestonePerk, IPerkBenefit, IPerksResponse } from "../types/perks";

export type initialStateType = {
  activeScreen: string;
  selectedMilestonePerk: null | IMilestonePerk;
  selectedPerkBenefits: IPerkBenefit[];
  perks: IPerksResponse;
};

export const initialState = {
  activeScreen: PerksScreens.Hub, // Default screen is Perks Hub
  selectedMilestonePerk: null,
  selectedPerkBenefits: [],
  perks: {
    currentMiles: "",
    upcomingMilestones: {
      nextMilestone: "150K",
      nextMilestoneMile: 150000,
      futureMilestone: "200K",
      futureMilestoneMile: 200000,
    },
    pastPerksList: [
      {
        perkBenefitId: 21,
        perkName: "$25 Discount Code",
        perkDescription: "$25 Discount Code",
        isEnabled: true,
        selectionDate: "10/07/2024",
        benefitExpirationDate: "10/11/2024",
        milestoneRequiredMiles: 100000,
      },
    ],
    availableMilestonePerks: [],
    currentPerks: {
      milestoneId: 2,
      milestoneRequiredMiles: 30000,
      milestoneName: "30K",
      numberOfPerksSelectable: 1,
      perkBenefitsLst: [],
    },
    nextPerks: {
      milestoneId: 0,
      milestoneRequiredMiles: 0,
      milestoneName: "",
      numberOfPerksSelectable: 0,
      perkBenefitsLst: [],
    },
    futurePerks: {
      milestoneId: 0,
      milestoneRequiredMiles: 30000,
      milestoneName: "30K",
      numberOfPerksSelectable: 1,
      perkBenefitsLst: [],
    },
    errorMessage: "",
    errorCode: "",
  },
};
