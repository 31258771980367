export const initialContentState = {
  Content: [
    {
      selectPerks: {
        title: "Select perks",
        titleColor: "Gray-gray-headline-900",
        cta: {
          backgroundColor: "Gray-white",
          singlePerkTitle: "select a perk",
          multiPerksTitle: "select 2 perks",
          titleColor: "Gray-gray-headline-900",
          multiPerksSubTitle: "You earned perks for reaching {milestone}! Select them now!",
          singlePerkSubTitle: "You earned a perk for reaching {milestone}! Select them now!",
          subTitleColor: "Gray-gray-headline-900",
          leftIcon: "sparkle-stroke",
          leftIconCategory: "interface",
          leftIconColor: "ds-color-icon-ui-default-default",
          leftIconShadowColor: "ds-text-primary-default",
          leftIconForegroundColor: "ds-color-tier-alaska-primary-member-default",
          ctaIcon: "chevron-right",
          ctaIconCategory: "interface",
          ctaIconColor: "Gray-gray-headline-800",
          actionUrl: "#",
        },
        backgroundColor: "gold-100",
      },
    },
    {
      perkSelection: {
        backgroundColor: "ds-color-background-secondary-100-default",
        ctaButtonTitle: "Select",
        ctaDisabledBackgroundColor: "ds-color-excursion-grey-200",
        ctaDisabledColor: "ds-text-primary-inverse",
        ctaEnabledBackgroundColor: "ds-color-excursion-blue-1000",
        ctaEnabledColor: "ds-text-primary-inverse",
        firstMultipleTitle: "Select your first perk",
        secondMultipleTitle: "Select your second perk",
        title: "Select your perk",
        titleColor: "Gray-gray-headline-900",
        subtitle: "You will be able to confirm your choice in the next step.",
        subtitleColor: "Gray-gray-headline-900",
        header: {
          backgroundColor: "ds-color-excursion-yellow-400",
          ctaColor: "ds-text-primary-default",
          subtitle:
            "You've successfully unlocked perks for reaching the {milestone} milestone! Make your selection below.",
          subtitleColor: "ds-text-primary-default",
          title: "select your {count} {perks}",
          titleColor: "ds-text-primary-default",
          cta: {
            href: "#",
            title: "learn more about perks",
          },
        },
        confirmSelection: {
          backgroundColor: "ds-color-background-secondary-100-default",
          title: "Confirm Selection?",
          titleColor: "Gray-gray-headline-900",
          subtitle: "Once you select your perks, this action can't be undone and your choice will be final",
          subtitleColor: "Gray-gray-headline-900",
          confirmButtonTitle: "Confirm",
          confirmButtonTextColor: "Gray-white",
          confirmButtonBackgroundColor: "ds-color-container-ui-primary-default-default",
          cancelButtonTitle: "Choose a different perk",
          cancelButtonBackgroundColor: "ds-color-border-ui-default-default",
          cancelButtonTextColor: "Gray-white",
          iconBackgroundColor: "ds-color-container-ui-primary-default-default",
          iconColor: "ds-color-container-ui-primary-default-default",
        },
        congratulationsSelection: {
          backgroundColor: "ds-color-background-secondary-100-default",
          title: "Congrats!",
          titleColor: "Gray-gray-headline-900",
          singlePerkText: "You've successfully selected your {milestone} Milestone perk.",
          singlePerkColor: "Gray-gray-headline-900",
          multiplePerkText: "You've successfully selected your {milestone} Milestone perks.",
          multiplePerkColor: "Gray-gray-headline-900",
          buttonText: "Return to Perks",
          buttonTextColor: "Gray-white",
          buttonBackgroundColor: "ds-color-excursion-blue-1000",
          iconBackgroundColor: "ds-color-excursion-blue-1000",
          iconColor: "ds-color-excursion-blue-1000",
        },
        top: {
          backgroundColor: "Green-green-200",
          leftIcon: "chevron-left",
          leftIconCategory: "interface",
          leftIconColor: "ds-text-primary-default",
          rightIcon: "x-sm",
          rightIconCategory: "interface",
          rightIconColor: "ds-text-primary-default",
          title: "Select your perk",
          multipleTitle: "Select your perks",
          titleColor: "Gray-gray-headline-800",
        },
        perk: {
          perkSelected: {
            borderColor: "ds-color-tier-alaska-primary-member-default",
            iconBackgroundColor: "ds-color-tier-alaska-primary-member-default",
            iconBorderColor: "ds-color-excursion-blue-1000",
            iconColor: "ds-color-icon-ui-default-default",
            subtitleColor: "Gray-gray-headline-900",
            titleColor: "Gray-gray-headline-800",
          },
          perkUnselected: {
            borderColor: "ds-color-border-ui-default-default",
            iconBackgroundColor: "ds-text-primary-default",
            iconBorderColor: "ds-color-excursion-blue-1000",
            iconColor: "ds-color-icon-ui-default-default",
            subtitleColor: "Gray-gray-headline-900",
            titleColor: "Gray-gray-headline-800",
          },
        },
      },
    },
    {
      icons: {
        list: [
          {
            perkId: "1",
            name: "food-and-drink",
            category: "in-flight",
          },
          {
            perkId: "2",
            name: "wifi",
            category: "in-flight",
          },
          {
            perkId: "3",
            name: "sparkle-stroke",
            category: "interface",
          },
          {
            perkId: "4",
            name: "elite-stroke",
            category: "in-flight",
          },
          {
            perkId: "5",
            name: "clear",
            category: "partnership",
          },
          {
            perkId: "6",
            name: "plane-diag-stroke",
            category: "terminal",
          },
        ],
        default: "sparkle-stroke",
        defaultCategory: "interface",
      },
    },
    {
      perksLanding: {
        title: "Perks",
        titleColor: "ds-text-primary-default",
        backgroundColor: "gold-100",
        welcomeMessage:
          "As part of the new Alaska loyalty program, when you achieve a new milestone you'll be able to come here to select from a choice of perks.",
        welcomeMessageColor: "ds-text-primary-default",
        seeAllPerks: {
          title: "View complete list of perks",
          titleColor: "ds-text-primary-default",
          actionUrl:
            "https://www.alaskaair.com/content/mileage-plan/membership-benefits?lid=mileageplan:mileage-plan-overview:membership-benefits",
        },
        milestoneSummary: {
          currentMilestoneDetails: {
            milesProgressMessage: "{m} / {tm} elite-qualifying miles",
            nextPerkMilestoneMessage: "to {name} perks milestone",
            eliteQualifyingMiles: "{qm} Elite-qualifying miles",
          },
          upcomingMilestoneDetails: {
            remainingMilestoneDetails: "Only **{remaining-miles} more to go** until your {next-milestone-required}.",
          },
        },
        pastPerks: {
          linkColor: "Gray-gray-headline-900",
          iconBackgroundColor: "ds-color-tier-alaska-primary-member-default",
          iconBackgroundBorderColor: "ds-color-tier-alaska-primary-member-default",
          iconColor: "",
          cardBackgroundColor: "ds-color-container-primary-default",
          fieldTitleColor: "Gray-gray-headline-900",
          fieldValueColor: "Gray-gray-headline-900",
          perkTitleColor: "Gray-gray-headline-900",
        },
        topNav: {
          backgroundColor: "Green-green-200",
          backIcon: "chevron-left",
          backIconCategory: "interface",
          backIconColor: "Gray-gray-headline-800",
          title: "Perks",
          titleColor: "Gray-gray-headline-800",
        },
      },
    },
    {
      perks: [
        {
          id: 6,
          perkSummary: "750 Bonus Miles",
          items: ["Item 1", "Item 2"],
        },
        {
          id: 7,
          perkSummary: "2500 Bonus Miles",
          items: ["Item 1", "Item 2"],
        },
        {
          id: 8,
          perkSummary: "5K Bonus Miles",
          items: ["Item 1", "Item 2"],
        },
        {
          id: 21,
          perkSummary: "$25 Discount",
          items: ["$25 Discount Item 1", "$25 Discount Item 2"],
          pastPerksCardProps: {
            fields: [
              {
                title: "Book by",
                value: "benefitExpirationDate",
                order: 1,
              },
              {
                title: "Milestone",
                value: "milestoneRequiredMiles",
                order: 2,
              },
            ],
            links: [
              {
                title: "Redeem code",
                href: "https://www.alaskaair.com/search?E=LOY25PERKDMJL000003",
                order: 1,
              },
            ],
          },
        },
        {
          id: 29,
          perkSummary: "2 Lounge Passes",
          items: [],
          perkName: "LOUNGE_DAY_PASS_2",
          pastPerksCardProps: {
            fields: [
              {
                title: "Use by",
                value: "benefitExpirationDate",
                order: "1",
              },
              {
                title: "Milestone",
                value: "milestoneRequiredMiles",
                order: "2",
              },
            ],
            links: [
              {
                title: "View lounge passes",
                href: "https://www.alaskaair.com/lounge-membership/passes",
                order: "1",
              },
            ],
          },
        },
      ],
    },
    {
      milestones: [
        {
          id: 0,
          name: "",
          milestoneDetails: "",
          chips: {
            backgroundColor: "ds-color-excursion-white-900",
            subtractColor: "",
            textColor: "ds-color-excursion-gray-900",
            title: "30K Milestone",
          },
          summary: "sample summary",
        },
        {
          id: 2,
          name: "30K",
          milestoneDetails:
            "You will receive the following when you reach your 30k status. You can use this benefits for every flight while you hold this status.",
          chips: {
            backgroundColor: "ds-color-excursion-white-900",
            subtractColor: "",
            textColor: "ds-color-excursion-gray-900",
            title: "MVP&reg;30K",
          },
          summary: "sample summary",
        },
        {
          id: 3,
          name: "55K",
          milestoneDetails: "You'll Unlock two one-time use perks at the 55K milestone.",
          chips: {
            backgroundColor: "ds-color-excursion-white-900",
            subtractColor: "",
            textColor: "ds-color-excursion-gray-900",
            title: "MVP&reg;GOLD 55K",
          },
          summary: "sample summary",
        },
        {
          id: 5,
          name: "30K",
          milestoneSummary:
            "You will recieved the following when you reach your 30k status. You can use this benefits for every flight while you hold this status.",
          chips: {
            backgroundColor: "ds-color-excursion-white-900",
            subtractColor: "",
            textColor: "ds-color-excursion-gray-900",
            title: "MVP&reg;30K",
          },
          summary: "sample summary",
        },
        {
          id: 6,
          name: "150k",
          milestoneSummary: "You'll Unlock two one-time use perks at the 150k milestone.",
          chips: {
            backgroundColor: "ds-color-excursion-blue-300",
            subtractColor: "ds-color-excursion-green-300",
            textColor: "ds-color-excursion-gray-90",
            title: "MVP&reg;GOLD 150K",
          },
        },
        {
          id: 7,
          name: "200k",
          milestoneSummary:
            "You will recieved the following when you reach your 200k status. You can use this benefits for every flight while you hold this status.",
          chips: {
            backgroundColor: "ds-color-excursion-blue-800",
            subtractColor: "ds-color-excursion-orange-500",
            textColor: "ds-color-excursion-orange-300",
            title: "MVP&reg;GOLD 200K",
          },
          summary: "",
        },
      ],
    },
  ],
  Error: { message: null },
};

const initialState = {
  Token: {
    Value: "",
    Guid: "",
  },
  MVPGoldNominationDetails: {
    FirstName: "",
    LastName: "",
    MileagePlanNumber: "",
    Email: "",
  },
  MileagePlanDetails: {
    MileagePlanNumber: "",
    FirstName: "",
    LastName: "",
    miles: "",
    TierName: "MVP®",
    MemberSelectionId: 0,
    MilestoneId: 0,
    MilestoneName: "",
    Email: "",
    TierEndDate: "",
    ChoiceSelectedStatus: "",
    HowTier: "",
    MP100KChoice: "",
    MemberBalance: 0,
    MilestoneAchievmentDate: "",
    SelectionExpirationDate: "",
    IsEligibleFor100KChoiceSelection: false,
    asoaMiles: 0,
    oneWorldTier: "Sapphire",
    Perks: [
      {
        PerkBenefitId: null,
        PerkName: "",
        PerkDescription: "",
        isEnabled: null,
      },
    ],
  },
  isSuperUser: false,
  Error: { message: null },
  SelectedChoiceMade: "",
  NominatedName: "",
};

export const initialPerkDataState = {
  currentMiles: null,
  upcomingMilestones: {
    nextMilestone: "150K",
    nextMilestoneMile: 150000,
    futureMilestone: "200K",
    futureMilestoneMile: 200000,
  },
  pastPerksList: [
    {
      perkBenefitId: 21,
      perkName: "$25 Discount Code",
      perkDescription: "$25 Discount Code",
      isEnabled: true,
      selectionDate: "10/07/2024",
      benefitExpirationDate: "2025-02-02T00:00:00",
      milestoneRequiredMiles: 100000,
      PerksFulfillmentKey: "LOY25PERKPMMK000006",
    },
  ],
  availableMilestonePerks: [
    {
      milestoneName: "30K",
      numberOfPerksSelectable: 1,
      milestoneRequiredMiles: 30000,
      memberSelectionLst: [
        {
          memberSelectionId: 3140,
          mpNumber: "880937676",
          milestoneId: 2,
          perkBenefitId: null,
          selectionDate: null,
          selectionExpirationDate: "2025-01-31T00:00:00",
          fulfillmentDate: null,
          milestoneAchievmentDate: "2024-09-04T23:25:45.69",
          milestoneAchievmentId: "333e7a4c-9cbf-4e4d-9068-e2ec5b0bb8b7",
        },
      ],
      perkBenefitsLst: [
        {
          perkBenefitId: 1,
          perkName: "100K CHOICE BENEFIT - ALASKA LOUNGE+",
          perkDescription: "100K Choice Lounge Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 4,
          perkName: "100K CHOICE BENEFIT - MVP GOLD NOMINATION",
          perkDescription: "100K Choice Gold Nom Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 5,
          perkName: "100K CHOICE BENEFIT - CLEAR",
          perkDescription: "100K Choice Clear Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 6,
          perkName: "$25 DISCOUNT CODE",
          perkDescription: "$25 DISCOUNT CODE Flag",
          isEnabled: true,
        },
      ],
    },
    {
      milestoneName: "100K",
      numberOfPerksSelectable: 1,
      milestoneRequiredMiles: 100000,
      memberSelectionLst: [
        {
          memberSelectionId: 3137,
          mpNumber: "880937676",
          milestoneId: 5,
          perkBenefitId: null,
          selectionDate: null,
          selectionExpirationDate: "2025-08-29T18:21:21.9",
          fulfillmentDate: null,
          milestoneAchievmentDate: "2024-08-29T18:21:21.9",
          milestoneAchievmentId: "521f7a48-1985-4f90-a6e3-e2e473fbebb9",
        },
      ],
      perkBenefitsLst: [
        {
          perkBenefitId: 1,
          perkName: "100K CHOICE BENEFIT - ALASKA LOUNGE+",
          perkDescription: "100K Choice Lounge Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 2,
          perkName: "100K CHOICE BENEFIT - WIFI",
          perkDescription: "100K Choice Wifi Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 3,
          perkName: "100K CHOICE BENEFIT - 50K BONUS MILES",
          perkDescription: "100K Choice 50K Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 4,
          perkName: "100K CHOICE BENEFIT - MVP GOLD NOMINATION",
          perkDescription: "100K Choice Gold Nom Flag",
          isEnabled: true,
        },
        {
          perkBenefitId: 5,
          perkName: "100K CHOICE BENEFIT - CLEAR",
          perkDescription: "100K Choice Clear Flag",
          isEnabled: true,
        },
      ],
    },
  ],
  currentPerks: {
    milestoneId: 2,
    milestoneRequiredMiles: 30000,
    milestoneName: "30K",
    numberOfPerksSelectable: 1,
    perkBenefitsLst: [
      {
        perkBenefitId: 1,
        perkName: "100K CHOICE BENEFIT - ALASKA LOUNGE+",
        perkDescription: "100K Choice Lounge Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 4,
        perkName: "100K CHOICE BENEFIT - MVP GOLD NOMINATION",
        perkDescription: "100K Choice Gold Nom Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 5,
        perkName: "100K CHOICE BENEFIT - CLEAR",
        perkDescription: "100K Choice Clear Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 6,
        perkName: "$25 DISCOUNT CODE",
        perkDescription: "$25 DISCOUNT CODE Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 13,
        perkName: "2500_BONUS_MILES",
        perkDescription: "2500 BONUS MILES",
        isEnabled: true,
      },
    ],
  },
  nextPerks: {
    milestoneId: 1,
    milestoneRequiredMiles: 55000,
    milestoneName: "55K",
    numberOfPerksSelectable: 2,
    perkBenefitsLst: [
      {
        perkBenefitId: 6,
        perkName: "100K CHOICE BENEFIT - MVP GOLD NOMINATION",
        perkDescription: "100K Choice Gold Nom Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 7,
        perkName: "100K CHOICE BENEFIT - CLEAR",
        perkDescription: "100K Choice Clear Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 8,
        perkName: "5K_BONUS_MILES",
        perkDescription: "5K BONUS MILES",
        isEnabled: true,
      },
    ],
  },
  futurePerks: {
    milestoneId: 5,
    milestoneRequiredMiles: 100000,
    milestoneName: "100K",
    numberOfPerksSelectable: 1,
    perkBenefitsLst: [
      {
        perkBenefitId: 1,
        perkName: "100K CHOICE BENEFIT - ALASKA LOUNGE+",
        perkDescription: "100K Choice Lounge Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 2,
        perkName: "WIFI_SUBSCRIPTION",
        perkDescription: "100K Choice Wifi Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 3,
        perkName: "100K CHOICE BENEFIT - 50K BONUS MILES",
        perkDescription: "100K Choice 50K Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 4,
        perkName: "100K CHOICE BENEFIT - MVP GOLD NOMINATION",
        perkDescription: "100K Choice Gold Nom Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 5,
        perkName: "100K CHOICE BENEFIT - CLEAR",
        perkDescription: "100K Choice Clear Flag",
        isEnabled: true,
      },
      {
        perkBenefitId: 16,
        perkName: "50K_BONUS_MILES",
        perkDescription: "50K BONUS MILES",
        isEnabled: true,
      },
      {
        id: 21,
        perkSummary: "$25 Discount Code",
        perkName: "$25 Discount Code",
        items: ["$25 Discount Code Item 1", "$25 Discount Code Item 2"],
      },
    ],
  },
  errorMessage: null,
  errorCode: null,
};

export type AccountStateType = typeof initialState;
export type ContentStateType = typeof initialContentState;
export type PerkDataStateType = typeof initialPerkDataState;

export default initialState;
