import "./index.scss";

interface RoundedButtonProps {
  className?: string;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
  type: "button" | "submit";
  outline?: boolean;
  testId?: string;
}

const RoundedButton = (props: RoundedButtonProps) => {
  const { className, label, disabled = false, onClick, type, outline, testId } = props;

  return (
    <button
      className={`roundedButton ${className} ${outline && "outline"}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid={testId}
    >
      {label}
    </button>
  );
};

export default RoundedButton;
