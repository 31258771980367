import { useContext, useEffect } from "react";
import { PerksContext } from "../../context/PerksContext";
import { PerksScreens } from "../../helpers/constants";
import PerksHub from "../MPPerks/MPPerksContainer";
import PerksSelection from "../MPPerksSelection/MPPerksSelection";
import PerksSelectionConfirmation from "../MPPerksSelection/MPPerksSelectionConfirmation";
import PerksSelectionSuccess from "../MPPerksSelection/MPPerksSelectionSuccess";
import "../../excursion.scss";

const MPPerksPage = () => {
  const { perksState } = useContext(PerksContext);
  const { activeScreen } = perksState;

  const screenComponents = {
    [PerksScreens.Hub]: <PerksHub />,
    [PerksScreens.PerksSelection]: <PerksSelection />,
    [PerksScreens.PerksSelectionConfirmation]: <PerksSelectionConfirmation />,
    [PerksScreens.PerksSelectionSuccess]: <PerksSelectionSuccess />,
  };

  useEffect(() => {
    if (activeScreen in screenComponents) {
      window.scrollTo(0, 0);
    }
  }, [activeScreen]);

  return screenComponents[activeScreen] || <></>;
};

export default MPPerksPage;
