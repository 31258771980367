import { useContext } from "react";
import cxs from "cxs";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { PerksScreens } from "../../../helpers/constants";
import RoundedButton from "../../RoundedButton/RoundedButton";
import { getIcon } from "../../../helpers/getIcon";
import WindowIcon from "../../WindowIcon";
import fetchPerksData from "../../../helpers/fetchPerksData";
import UserAccountContext from "../../../context/UserAccountContext";
import { isFromMobileWebView } from "../../../services/header-footer";
import "./index.scss";
import { IGetPerksRequest } from "../../../types/perks";

const MPPerksSelectionSuccess = () => {
  const { accountState } = useContext(UserAccountContext);
  const { contentState } = useContext(ContentContext);
  const { perksState, perksDispatch } = useContext(PerksContext);
  const { MileagePlanNumber, asoaMiles } = accountState.MileagePlanDetails;
  const { Guid, Value } = accountState.Token;

  const { selectedPerkBenefits, selectedMilestonePerk } = perksState;

  const {
    backgroundColor,
    titleColor,
    title,
    buttonBackgroundColor,
    buttonText,
    singlePerkColor,
    singlePerkText,
    multiplePerkColor,
    multiplePerkText,
    iconBackgroundColor,
    iconColor,
  } = contentState?.Content[1]?.perkSelection?.congratulationsSelection || {};

  const subtitleText =
    selectedPerkBenefits.length > 1
      ? multiplePerkText?.replace(
          "{milestone}",
          selectedMilestonePerk?.milestoneName ?? ""
        ) ??
        "You've succesfully selected your {milestone} Milestone perks."?.replace(
          "{milestone}",
          selectedMilestonePerk?.milestoneName ?? ""
        )
      : singlePerkText?.replace(
          "{milestone}",
          selectedMilestonePerk?.milestoneName ?? ""
        ) ??
        "You've succesfully selected your {milestone} Milestone perk."?.replace(
          "{milestone}",
          selectedMilestonePerk?.milestoneName ?? ""
        );

  const { iconBorderColor: selectedIconBorderColor } =
    contentState?.Content[1]?.perkSelection?.perk?.perkSelected || {};

  const {
    list,
    default: defaultIconName,
    defaultCategory,
  } = contentState?.Content[2]?.icons || {};

  const styles = {
    container: cxs({
      background: `var(--${backgroundColor}, #FBFFF6)`,
      paddingTop: isFromMobileWebView() ? "5rem" : "3rem",
    }),
    header: cxs({ color: `var(--${titleColor}, #151515)` }),
    subtitle: cxs({
      color: `var(--${
        selectedPerkBenefits.length > 1 ? multiplePerkColor : singlePerkColor
      }, #2e2e2e)`,
    }),
    iconContainer: cxs({
      color: `var(--${iconColor}, #03314F)`,
      borderColor: `var(--${selectedIconBorderColor}, #2CD7E1)`,
      backgroundColor: `var(--${iconBackgroundColor}, #2CD7E1)`,
    }),
    confirmButton: cxs({
      color: `#fff`,
      backgroundColor: `var(--${buttonBackgroundColor}, #03314f)`,
    }),
  };

  const handleGoToPerks = async () => {
    const payload: IGetPerksRequest = {
      asoaMiles: asoaMiles,
      guid: Guid,
      mileagePlanNumber: MileagePlanNumber,
    };
    await fetchPerksData(payload, Value, perksDispatch);
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: PerksScreens.Hub,
    });
  };

  return (
    <div
      className={`perksSuccessPage__container ${styles.container}`}
      data-testid="perks-selection-success"
    >
      <div className="perksSuccessPage__headerContainer">
        <div className="perksSuccessPage__icons">
          {selectedPerkBenefits.map((perk) => {
            const icon = getIcon(
              perk.perkBenefitId,
              list,
              defaultCategory,
              defaultIconName
            );
            return (
              <WindowIcon
                testId="perk-icon"
                key={perk.perkBenefitId}
                icon={icon.name}
                category={icon.category}
                variant={"solid"}
                iconContainerClassName={styles.iconContainer}
                size="large"
              />
            );
          })}
        </div>
        <div className="perksSuccessPage__headerText">
          <h1 className={`perksSuccessPage__title ${styles.header}`}>
            {title}
          </h1>
          <p className={`perksSuccessPage__subtitle ${styles.subtitle}`}>
            {subtitleText}
          </p>
        </div>
      </div>
      <div className="perksSuccessPage__buttons">
        <RoundedButton
          testId="return-to-hub-button"
          className={styles.confirmButton}
          label={buttonText ?? "Return to Perks"}
          type="button"
          onClick={handleGoToPerks}
        />
      </div>
    </div>
  );
};

export default MPPerksSelectionSuccess;
